<script setup lang="ts">
/**
 * @author Luka Tchigladze, Irakli Kandelaki
 */

//@ts-nocheck
/** IMPORT VUE HOOKS */
import { ref, computed, onMounted, onBeforeUnmount, nextTick, watch } from "vue";

/** IMPORT TYPES */
import type { Ref, ComputedRef } from "vue";
import type { ImageInterface, SecondaryDataInterface } from "@/types/ContentType";

/** IMPORT STORE */
import { useMainStore } from "@/store/main/index";

/** IMPORT COMPONENTS */

const CaretTitle = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/CaretTitle.vue")
);
const Navigation = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Slider/Navigation.vue")
);

const Button = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Button.vue")
);
const Grid = defineAsyncComponent(() => import("./parts/Grid.vue"));
const Link = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Link.vue")
);

/** IMPORT COMPOSABLES */
import { handleSwipeDirection } from "@/composables/utils/swipeHandler";
import { isInView } from "@/composables/utils/isInView";

/** PROPS */
const props = defineProps<{
  contentData?: SecondaryDataInterface;
}>();

/** INIT STORE */
const mainStore = useMainStore();

const lang = computed(() => mainStore.lang);

/** REACTIVE DATA */
const slideIndex: Ref<number> = ref(0);
const zIndexes: Ref<(number | undefined)[]> = ref([]);
const lastDirection: Ref<boolean> = ref(true);

const sliderKey = ref(0);

const sliderActive: Ref<boolean> = ref(false);
const slideStart: Ref<boolean> = ref(false);
const slideEnd: Ref<boolean> = ref(false);
const isIntroEnded: Ref<boolean> = ref(false);

const slideInterval: Ref<number | undefined> = ref();
const sliderInitTimeout: Ref<number | undefined> = ref();
const slideStartTimeout: Ref<number | undefined> = ref();
const slideEndTimeout: Ref<number | undefined> = ref();
const slideDuration: Ref<number> = ref(5400);
const slideDurationMedium: Ref<number> = ref(1500);
const slideDurationSmall: Ref<number> = ref(1200);

const textAnimation: Ref<boolean> = ref(false);

const sliderEl: Ref<Element | undefined> = ref();
const isTrigger: Ref<boolean> = ref(false);

const windowWidth: ComputedRef<number> = computed(() => mainStore.windowWidth);

const isDesktop = computed(() => windowWidth.value >= 1248);

const computedSlideIndex = computed(() => {
  return zIndexes.value.findIndex((i) => i === zIndexes.value.length);
});

const leftPad = computed(() => {
  const paddingMap = {
    desktop_2k: 276,
    desktop_lg: 207,
    desktop: 104,
    tablet: 104,
    mobile: 39,
    mobile_sm: 39,
    default: 414,
  };

  const key =
    Object.entries(mainStore.breakpoints)
      .reverse()
      .find(([_, val]) => windowWidth.value <= val)?.[0] || "default";

  return paddingMap[key];
});

const caretTitleSize = computed(() =>
  mainStore.windowWidth >= 3840
    ? 428
    : mainStore.windowWidth >= 2560
    ? 207
    : mainStore.windowWidth >= 1920
    ? 164
    : mainStore.windowWidth >= 1440
    ? 154
    : 122
);

/** DECONSTRUCT SWIPE DIRECTION HANDLER */
const { handleSwipeStart, handleSwipeEnd, direction } = handleSwipeDirection();

const onEvents = {
  touchstart(e: TouchEvent) {
    isDesktop.value ? null : handleSwipeStart(e);
  },
  touchend(e: TouchEvent) {
    isDesktop.value ? null : handleSwipeEnd(e);
  },
};

/** GET PROPER IMAGE SOURCE BY BREAKPOINT */
const getImageSource = (image: ImageInterface): string => {
  if (mainStore.windowWidth <= 983) return image?.devices?.mobile;
  else if (mainStore.windowWidth <= 1247) return image?.devices?.tablet;
  else return image?.devices?.desktop;
};

const changeZIndex = (forward: boolean = true) => {
  if (lastDirection.value !== forward) {
    let newVal = [];
    let index;

    if (forward) {
      for (let i = props.contentData?.data?.list?.length; i > 0; i--) {
        newVal.push(i);
      }

      index =
        slideIndex.value === 1
          ? 0
          : slideIndex.value
          ? newVal.length + 1 - slideIndex.value
          : 1;
    } else {
      for (let i = 1; i <= props.contentData?.data?.list?.length; i++) {
        newVal.push(i);
      }

      index =
        slideIndex.value === newVal.length - 1
          ? slideIndex.value
          : newVal.length - 2 - slideIndex.value;
    }

    newVal = [...newVal.slice(index), ...newVal.slice(0, index)];

    zIndexes.value = newVal;
  } else {
    if (forward) {
      zIndexes.value.unshift(zIndexes.value.pop());
    } else {
      zIndexes.value.push(zIndexes.value.shift());
    }
  }

  lastDirection.value = forward;
};

const changeSlideIndex = (forward: boolean = true) => {
  slideEnd.value = true;
  // SETTING TIME MAKES ANIMATION STOP WHEN CHANGING DIRECTIONS
  setTimeout(() => {
    if (slideIndex.value == 0 && !forward) {
      slideIndex.value = zIndexes.value.length - 1;
    } else if (slideIndex.value >= zIndexes.value.length - 1 && forward) {
      slideIndex.value = 0;
    } else if (!forward) {
      slideIndex.value--;
    } else {
      slideIndex.value++;
    }
  }, 500);
};

const slideNav = async (forward: boolean = true) => {
  textAnimation.value = true;
  clearTimeout(slideEndTimeout.value);
  clearInterval(slideInterval.value);

  changeSlideIndex(forward);

  await nextTick();

  changeZIndex(forward);

  slideEndTimeout.value = window.setTimeout(() => {
    slideEnd.value = false;
  }, slideDurationSmall.value);

  autoSlide();
};

const autoSlide = () => {
  clearInterval(slideInterval.value);
  slideInterval.value = window.setInterval(async () => {
    clearTimeout(slideEndTimeout.value);
    changeSlideIndex();

    await nextTick();

    changeZIndex();

    slideEndTimeout.value = window.setTimeout(() => {
      slideEnd.value = false;
    }, slideDurationSmall.value);
  }, slideDuration.value);
};

const sliderInit = async () => {
  textAnimation.value = true;
  sliderActive.value = true;
  changeSlideIndex();
  slideEnd.value = false;

  autoSlide();
};

const handleIsTrigger = (): void => {
  isTrigger.value = isInView(sliderEl.value, 1);
};

const buildLinkObj = (link) => {
  const url = link?.url.startsWith("/")
    ? `/${lang.value}/${link?.url.slice(1)}`
    : link?.url;

  return {
    title: link?.title,
    url: url,
  };
};

/** MOUNTED */
onMounted(async () => {
  const listCount = props?.contentData?.data?.listCount;

  if (props?.contentData && listCount) {
    if (listCount === 1) {
      props.contentData.data.list = [
        ...props.contentData.data.list,
        ...props.contentData.data.list,
      ];
    }
    for (let i = props.contentData.data.list.length; i > 0; i--) {
      zIndexes.value.push(i);
    }
  }

  await nextTick();

  sliderKey.value++;
  if (isTrigger.value) {
    window?.removeEventListener("scroll", handleIsTrigger);
    return;
  }

  window?.addEventListener("scroll", handleIsTrigger);
});

/** WATCHERS */
watch(direction, (ns) => {
  if (ns === "LEFT") {
    slideNav(true);
  } else if (ns === "RIGHT") {
    slideNav(false);
  }
});

watch(isTrigger, (ns) => {
  if (!ns) return;

  isIntroEnded.value = true;
  slideStart.value = true;

  sliderInit();
});

/** BEFORE UNMOUNT */
onBeforeUnmount(() => {
  clearInterval(slideInterval.value);
  clearTimeout(sliderInitTimeout.value);
  clearTimeout(slideStartTimeout.value);
  clearTimeout(slideEndTimeout.value);

  window?.removeEventListener("scroll", handleIsTrigger);
});
</script>

<template>
  <section
    class="design-slider"
    :class="{
      'anim-end': textAnimation && slideEnd,
      'intro-end': isIntroEnded,
      'is-inview': isTrigger,
    }"
    :style="{
      '--duration': slideDurationMedium + 'ms',
      '--duration-sm': slideDurationSmall + 'ms',
      '--duration-xs': slideDurationSmall / 2 + 'ms',
      '--side-pad': leftPad + 'px',
    }"
    ref="sliderEl"
  >
    <div :key="sliderKey" class="design-slider__wrapper">
      <div class="container design-slider__header">
        <CaretTitle :title="contentData?.conf?.header?.title" />
      </div>

      <div
        class="design-slider__body pos-rel"
        v-if="contentData?.data?.list?.length"
        v-on="onEvents"
      >
        <div
          v-for="(slide, index) in contentData?.data?.list"
          class="design-slider__image d-flex pos-abs"
          :class="{ 'image-active': sliderActive && computedSlideIndex === index }"
          :style="{
            'z-index': zIndexes?.[index],
          }"
        >
          <svg
            :viewBox="`0 0 ${mainStore.windowWidth} ${
              mainStore.windowWidth >= 1248
                ? mainStore.windowHeight - caretTitleSize
                : mainStore.windowWidth / 1.94957983193
            }`"
          >
            <mask :id="`myMask-${index + 1}`" class="mask">
              <rect
                v-for="i in 6"
                :class="['rectangle-move', i % 2 ? 'rectangle-up' : 'rectangle-down']"
                :style="{
                  'animation-delay': 100 * (i - 1) + 'ms',
                }"
                :x="
                  i === 1
                    ? 0
                    : i > 2
                    ? leftPad + ((windowWidth - leftPad * 2) / 4) * (i - 2)
                    : leftPad
                "
                y="0"
                width="100%"
                :height="`${
                  mainStore.windowWidth >= 1248
                    ? mainStore.windowHeight - caretTitleSize
                    : '100%'
                }`"
                fill="white"
              />
            </mask>

            <image
              class="image"
              x="0"
              y="0"
              width="100%"
              :height="`${
                mainStore.windowWidth >= 1248
                  ? mainStore.windowHeight - caretTitleSize
                  : '100%'
              }`"
              :href="getImageSource(slide?.image?.[0])"
              preserveAspectRatio="xMidYMid slice"
              :mask="`url(#myMask-${index + 1})`"
            />
          </svg>
        </div>
        <div class="design-slider__overlay pos-abs ev-none">
          <div class="container pos-rel d-flex al-center">
            <Grid :isTrigger="isTrigger" />

            <ClientOnly>
              <div
                v-if="isDesktop"
                class="design-slider__info pos-rel ev-all bg-white ov-hidden"
              >
                <div class="design-slider__info-in d-flex dir-col h-full">
                  <div class="design-slider__navigation d-flex">
                    <Navigation
                      class="ev-all"
                      @forward="slideNav(true)"
                      @back="slideNav(false)"
                    />
                  </div>

                  <div class="design-slider__info-content d-flex dir-col j-end pos-rel">
                    <div class="design-slider__info-content-in">
                      <h2 class="c-black pos-rel ov-hidden no-flicker hl-3">
                        {{ contentData?.data?.list?.[slideIndex]?.title }}
                      </h2>

                      <h3 class="pos-rel ov-hidden p-16">
                        {{ contentData?.data?.list?.[slideIndex]?.teaser }}
                      </h3>

                      <Link :label="contentData?.data?.list?.[slideIndex].link?.title + ' ' + contentData?.data?.list?.[slideIndex]?.teaser" :link="buildLinkObj(contentData?.data?.list?.[slideIndex]?.link)">
                        <div
                          v-if="contentData?.data?.list?.[slideIndex]?.link?.title"
                          class="design-slider__button ov-hidden d-inline-block"
                        >
                          <Button
                            :btnType="3"
                            :noTranslate="true"
                            :btnText="contentData?.data?.list?.[slideIndex].link?.title"
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!isDesktop" class="design-slider__navigation w-full d-flex pos-abs">
                <Navigation
                  class="ev-all"
                  @forward="slideNav(true)"
                  @back="slideNav(false)"
                />
              </div>

              <div
                class="design-slider__progress pos-abs"
                :class="{ 'design-slider__progress--start': slideStart && !slideEnd }"
              >
                <template v-if="isDesktop">
                  <div class="design-slider__progress--1 d-flex pos-abs ov-hidden">
                    <div class="design-slider__progress-bar--1 bg-mango"></div>
                  </div>

                  <div class="design-slider__progress--2 d-flex j-end pos-abs ov-hidden">
                    <div class="design-slider__progress-bar--2 bg-mango"></div>
                  </div>

                  <div class="design-slider__progress--3 d-flex al-end pos-abs ov-hidden">
                    <div class="design-slider__progress-bar--3 bg-mango"></div>
                  </div>
                </template>

                <div
                  class="design-slider__progress--4 d-flex al-end pos-abs ov-hidden"
                  v-else
                >
                  <div class="design-slider__progress-bar--4 bg-mango"></div>
                </div>
              </div>
            </ClientOnly>
          </div>
        </div>
      </div>

      <ClientOnly>
        <div v-if="!isDesktop" class="design-slider__info bg-white">
          <div class="design-slider__info-content d-flex dir-col j-end pos-rel ov-hidden">
            <div class="design-slider__info-content-in">
              <h2
                class="c-black pos-rel ov-hidden no-flicker"
                v-if="contentData?.data.list[slideIndex]?.title"
              >
                {{ contentData?.data?.list?.[slideIndex]?.title }}
              </h2>

              <h3
                class="pos-rel ov-hidden p-16"
                v-if="contentData?.data.list[slideIndex]?.teaser"
              >
                {{ contentData?.data?.list?.[slideIndex]?.teaser }}
              </h3>

              <Link :label="contentData?.data?.list?.[slideIndex]?.link?.title + ' ' + contentData?.data?.list?.[slideIndex]?.teaser" :link="buildLinkObj(contentData?.data.list[slideIndex]?.link)">
                <div
                  v-if="contentData?.data.list[slideIndex]?.link?.title"
                  class="design-slider__button ov-hidden"
                >
                  <Button
                    :btnType="3"
                    :noTranslate="true"
                    :btnText="contentData?.data?.list?.[slideIndex]?.link?.title"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </ClientOnly>
    </div>
  </section>
</template>

<style lang="scss" src="./styles/designSlider.styles.scss"></style>
